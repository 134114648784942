<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <v-icon>
            {{ icons.mdiDatabaseClockOutline }}
          </v-icon>
          <span class="text-h5" style="margin-left:10px">Historie odesílaní</span>
        </v-card-title>

        <!-- TABULKA -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-data-table
                  full-width
                  height="600"
                  dense
                  :headers="headers"
                  :items="table_data_history"
                  no-data-text="Data nenalezena..."
                  item-key="_id"
                  show-expand
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  :loading="loading"
                  loading-text="Získávam data ze serveru... Prosím čekejte"
                  @update:options="tableOptChanged"
                  :items-per-page="fetch_settings.paging.limit"
                  :server-items-length="fetch_settings.paging.total_records"
                  :page="fetch_settings.paging.current_page"
                  :footer-props="{ itemsPerPageOptions: [15, 30, 100], itemsPerPageText: 'Počet položek na stránku' }"
                  sort-by="createdon"
                  :sort-desc="true"
                >
                  <!-- datum -->
                  <template v-slot:item.createdon="{ item }">
                    <span>{{ fullDate(item.createdon) }}</span>
                  </template>
                  <!-- status -->
                  <template v-slot:item.result.status="{ item }">
                    <span v-if="item.result.status === true" style="color:#9C27B0">odesláno</span>
                    <span v-else style="color:#F44336">neodesláno</span>
                  </template>
                  <!-- expand -->
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <div style="padding-top:10px; padding-bottom:15px;">
                        <json :json_data="item.result.mailserver_info || item.result.err" />
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- TLACITKA -->
        <v-card-actions>
          <v-row>
            <v-col :cols="2">

            </v-col>
            <!-- btn: zrusit -->
            <v-col :cols="10" style="text-align: right;">
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDialog"
              >
                Zavřít
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </v-row>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
import axios from 'axios'
import { mdiEmailEdit, mdiHelpCircleOutline, mdiDatabaseClockOutline } from '@mdi/js'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'
import Json from '@/components/json/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    Json,
  },
  mixins: [response],
  props: {
    tabs_h: {
      type: Number,
      default: 250,
    },
    show: {
      type: Boolean,
      default: false,
    },
    history_item_id: {
      type: String,
      default: '',
    },
  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
    history_item_id(new_id) {
      if (new_id && new_id.length > 0) {
        this.fetchHistory(new_id)
      }
    },
  },
  data() {
    return {
      loading: false,
      table_data_history: [],
      headers: [
        {
          text: 'Datum',
          align: 'start',
          value: 'createdon',
        },
        {
          text: 'Stav',
          align: 'start',
          value: 'result.status',
        },
      ],
      expanded: [],
      singleExpand: false,

      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      // icony
      icons: {
        mdiEmailEdit,
        mdiHelpCircleOutline,
        mdiDatabaseClockOutline,
      },

      fetch_settings: {
        filter: {
          cd_rel: 'newsletter_address',
          id_rel: this.history_item_id,
          type: 'send',
        },
        paging: {
          limit: 15,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          createdon: -1,
        },
      },
    }
  },
  created() {
  },
  methods: {
    closeDialog() {
      this.$emit('close', false)
    },
    tableOptChanged(p_opt) {
      this.fetch_settings.sort = {}

      // sortovanie
      if (p_opt.sortBy[0]) {
        let sortDesc = p_opt.sortDesc[0] === false ? 1 : -1
        this.fetch_settings.sort[p_opt.sortBy[0]] = sortDesc
      }

      // pocet riadkov na stranu
      if (p_opt.itemsPerPage) {
        this.fetch_settings.paging.limit = p_opt.itemsPerPage
      }

      // aktualna strana
      if (p_opt.page) {
        this.fetch_settings.paging.current_page = p_opt.page
        this.fetch_settings.paging.skip = (p_opt.page * this.fetch_settings.paging.limit) - this.fetch_settings.paging.limit
      }

      // volaj API
      this.fetchHistory(this.history_item_id)
    },
    fetchHistory(p_id) {
      this.loading = true
      this.fetch_settings.filter = {
        cd_rel: 'newsletter_address',
        id_rel: p_id,
        type: 'send',
      }
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/history/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.table_data_history = resp.data.db_data
          this.loading = false
          this.fetch_settings.paging.total_pages = Math.ceil(resp.data.db_records / this.fetch_settings.paging.limit)
          this.fetch_settings.paging.total_records = resp.data.db_records
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    fullDate(p_date) {
      if (p_date) {
        return this.$moment(p_date).format('DD.MM.YYYY HH:mm:ss')
      }

      return p_date
    },
  },
}

</script>

<style>

</style>
