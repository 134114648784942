import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{style:(("height:" + _vm.tabs_h + "px;margin-bottom:25px;"))},[_c(VTabs,{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.icon},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('email-management',{attrs:{"tabs_h":_vm.tabs_h},on:{"snackbar":_vm.snackBarMsg}})],1),_c(VTabItem,[_c('email-template',{attrs:{"tabs_h":_vm.tabs_h},on:{"snackbar":_vm.snackBarMsg}})],1),_c(VTabItem,[_c('email-timer',{attrs:{"tabs_h":_vm.tabs_h},on:{"snackbar":_vm.snackBarMsg}})],1)],1)],1),_c('snack-bar',{attrs:{"snackbar":_vm.snackbar,"color":_vm.snack_color,"text":_vm.snack_text},on:{"close":function($event){_vm.snackbar=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }