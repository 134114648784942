<template>
  <div>
    <v-container class="karta">
      <!-- BTN -->
      <!-- pridaj -->
      <v-btn
        color="primary"
        fab
        top
        right
        absolute
        class="mx-2"
        @click="show_dialog_email_crud=true"
        title="Přidat emailovú adresu"
        style="top:5px;right:30px">
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
      <!-- import -->
      <v-btn
        color="primary"
        fab
        top
        right
        absolute
        small
        class="mx-2"
        @click="show_dialog_email_import=true"
        title="Import z csv"
        style="top:15px;right:90px">
        <v-icon>
          {{ icons.mdiFileImportOutline }}
        </v-icon>
      </v-btn>
      <!-- TABULKA -->
      <v-card outlined>
        <v-card-title>
          <!-- hladaj... -->
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Hledej"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-card-title>
        <v-data-table
          full-width
          dense
          :headers="headers"
          :items="table_data"
          :height="tabs_h - 220"
          no-data-text="Data nenalezena..."
          @update:options="tableOptChanged"
          :items-per-page="fetch_settings.paging.limit"
          :server-items-length="fetch_settings.paging.total_records"
          :page="fetch_settings.paging.current_page"
          :footer-props="{ itemsPerPageOptions: [20, 50, 200, 600], itemsPerPageText: 'Počet položek na stránku' }"
          sort-by="date_last_send"
          :sort-desc="true"
        >
          <!-- email_address -->
          <template v-slot:item.email_address="{ item }">
            <span class="link-cell" @click="setModifyItem(item)">{{ item.email_address }}</span>
            <span v-if="item.reason_reject===30">
              <v-icon
                style="margin-left:5px;color:#9C27B0;cursor:help;"
                title="emailový kontakt se sám odhlasil z odběru newsletteru (odkazem)"
                x-small>
                {{ icons.mdiEmoticonSadOutline }}
              </v-icon>
            </span>
            <span v-if="item.reason_reject===40">
              <v-icon
                style="margin-left:5px;color:#FF80AB;cursor:help;"
                title="emailový kontakt nás kontaktoval, že chce být vyřazen z odběru"
                x-small>
                {{ icons.mdiEmoticonSadOutline }}
              </v-icon>
            </span>
            <span v-if="item.reason_reject===90">
              <v-icon
                style="margin-left:5px;color:#E91E63;cursor:help;"
                title="jiný důvod"
                x-small>
                {{ icons.mdiEmoticonSadOutline }}
              </v-icon>
            </span>
          </template>
          <!-- last send -->
          <template v-slot:item.date_last_send="{ item }">
            <span :class="`${item.date_last_send ? 'link-cell' : ''}`" @click="logHistory(item)">{{ lastSend(item.date_last_send) }}</span>
            <v-icon
              v-if="item.last_history_id && item.last_history_id.result && item.last_history_id.result.err"
              style="margin-left:5px;color:#FF6F00;cursor:help;"
              title="Při posledním odeslání došlo k chybě."
              x-small>
              {{ icons.mdiAlertRhombusOutline }}
            </v-icon>
          </template>
          <!-- is_allowed -->
          <template v-slot:item.is_allowed="{ item }">
            <v-checkbox
              v-model="item.is_allowed"
              readonly
              hide-details
              style="margin-top: 0px;"
              :title="item.is_allowed ? 'Odesílaní newsletteru pro tento kontakt je povoleno.' : 'Odesílaní newsletteru pro tento kontakt je zakázáno.'"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card>
      <!-- STATS -->
       <div class="stats">celkom kontaktov: {{ contacts_total }} | z toho odhlásených kontaktov: {{ contacts_disabled }} | zostáva aktívnych kontaktov: {{ contacts_enabled }}</div>
      <!-- medzera -->
      <div style="display:block;height:20px"></div>
    </v-container>

    <!-- DIALOG: pridaj/uprav emailovy kontakt -->
    <dialog-email-crud :show="show_dialog_email_crud" :item_id="item_id" @close="closeDialogEmailCRUD"></dialog-email-crud>
    <!-- DIALOG: pre import emailovych kontaktov -->
    <dialog-email-import :show="show_dialog_email_import" @close="closeDialogEmailImport"></dialog-email-import>
    <!-- DIALOG: historia emailoveho kontaktu -->
    <dialog-email-history :show="show_dialog_email_history" :history_item_id="history_item_id" @close="closeDialogEmailHistory"></dialog-email-history>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
/* eslint quote-props: "off" */
import axios from 'axios'
import latinize from 'latinize'
import { mdiContentSave, mdiExportVariant, mdiFileImportOutline, mdiPlus, mdiMagnify, mdiEmoticonSadOutline, mdiAlertRhombusOutline } from '@mdi/js'
import response from '@/mixins/response'
import DialogEmailCRUD from './DialogEmailCRUD.vue'
import DialogEmailImport from './DialogEmailImport.vue'
import DialogEmailHistory from './DialogHistory.vue'

export default {
  components: {
    'dialog-email-crud': DialogEmailCRUD,
    'dialog-email-import': DialogEmailImport,
    'dialog-email-history': DialogEmailHistory,
  },
  mixins: [response],
  props: {
    tabs_h: {
      type: Number,
      default: 250,
    },
  },
  computed: {
  },
  watch: {
    search: function (new_search) {
      clearTimeout(this.searchTimeout) // Zrušíme predchádzajúci časovač

      if (new_search && new_search.length > 0) {
        let filter = {
          $or: [
            { 'email_address': { $regex: `${new_search}`, $options: 'i' } },
            { 'latin_email_contact_name': { $regex: `${latinize(new_search)}`, $options: 'i' } },
          ],
        }

        this.searchTimeout = setTimeout(() => {
          this.fetchNewsletterContacts(filter)
        }, 250) // Počkáme 100 ms a potom vykonáme funkciu fetchNewsletterContacts s filtráciou
      } else {
        this.fetchNewsletterContacts({})
      }
    },
  },
  data() {

    return {
      search: '',
      searchTimeout: null,
      headers: [
        {
          text: 'Emailová adresa',
          align: 'start',
          value: 'email_address',
        },
        {
          text: 'Názov kontaktu',
          align: 'start',
          value: 'email_contact_name',
        },
        {
          text: 'Naposledy odesláno',
          value: 'date_last_send',
        },
        {
          text: 'Odesílat',
          value: 'is_allowed',
        },
      ],
      table_data: [],

      show_dialog_email_crud: false, // zobraz dialog na pridanie/upravu email. kontaktu
      show_dialog_email_import: false, // zobraz dialog na import email. kontaktov
      show_dialog_email_history: false, // zobraz dialog s historriou odesilani pre email. kontakt

      item_id: '',
      history_item_id: '',

      // icons
      icons: {
        mdiContentSave,
        mdiExportVariant,
        mdiFileImportOutline,
        mdiPlus,
        mdiMagnify,
        mdiEmoticonSadOutline,
        mdiAlertRhombusOutline,
      },

      loading: false,
      fetch_settings: {
        filter: {},
        paging: {
          limit: 10,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          date_last_send: -1,
        },
      },

      contacts_total: 0,
      contacts_disabled: 0,
      contacts_enabled: 0,
    }
  },
  created() {
    this.fetchNewsletterContacts()
    this.fetchStatsContact()
  },
  methods: {
    closeDialogEmailCRUD(p_show, p_refresh) {
      this.item_id = ''
      this.show_dialog_email_crud = p_show
      if (p_refresh) {
        this.fetchNewsletterContacts()
      }
    },
    closeDialogEmailImport(p_show, p_refresh) {
      this.show_dialog_email_import = p_show
      if (p_refresh) {
        this.fetchNewsletterContacts()
      }
    },
    closeDialogEmailHistory(p_show) {
      this.show_dialog_email_history = p_show
    },
    fetchNewsletterContacts(p_filter) {
      this.loading = true
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.table_data = resp.data.db_data
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.db_records / this.fetch_settings.paging.limit
          this.fetch_settings.paging.total_records = resp.data.db_records
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    setModifyItem(p_item) {
      if (p_item && p_item._id) {
        this.item_id = p_item._id
        this.show_dialog_email_crud = true
      } else {
        this.item_id = ''
      }
    },
    lastSend(p_date) {
      return (p_date && this.$moment(p_date).format('DD.MM.YYYY HH:mm:ss')) || 'ještě neodesláno'
    },
    tableOptChanged(p_opt) {
      console.log(p_opt)
      this.fetch_settings.sort = {}

      // sortovanie
      if (p_opt.sortBy[0]) {
        let sortDesc = p_opt.sortDesc[0] === false ? 1 : -1
        this.fetch_settings.sort[p_opt.sortBy[0]] = sortDesc
      }

      // pocet riadkov na stranu
      if (p_opt.itemsPerPage) {
        this.fetch_settings.paging.limit = p_opt.itemsPerPage
      }

      // aktualna strana
      if (p_opt.page) {
        this.fetch_settings.paging.current_page = p_opt.page
        this.fetch_settings.paging.skip = (p_opt.page * this.fetch_settings.paging.limit) - this.fetch_settings.paging.limit
      }

      // volaj API
      this.fetchNewsletterContacts()
    },
    logHistory(p_item) {
      this.show_dialog_email_history = true
      this.history_item_id = p_item._id
    },
    fetchStatsContact() {
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/stats/total_stats`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          this.contacts_total = resp.data.total
          this.contacts_enabled = resp.data.online
          this.contacts_disabled = resp.data.offline
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}

</script>

<style>
  .karta {
    width: 100%;
    padding: 30px
  }
  .link-cell:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #9C27B0;
  }
  .stats {
    text-align: center;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #E0DFE2;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
  }
</style>
