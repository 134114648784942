<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="show"
        persistent
        max-width="600px"
      >
        <v-card min-height="700px">
          <v-card-title>
            <v-icon>
              {{ icons.mdiEmailEdit }}
            </v-icon>
            <span class="text-h5" style="margin-left:10px">Správa emailového kontaktu</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="form_valid"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <!-- emailova adresa -->
                    <v-text-field
                      v-model="dataForm.email_address"
                      label="Emailová adresa"
                      hint="Napr.: jozko.mrkvicka@email.cz"
                      :rules="emailRules"
                      required
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <!-- nazov emailovy kontaktu -->
                    <v-text-field
                      v-model="dataForm.email_contact_name"
                      label="Název kontaktu"
                      hint="Napr.: Jožko Mrkvička"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <!-- poznamka -->
                    <v-textarea
                      v-model="dataForm.note"
                      label="Poznámka"
                      hint="Interní poznámka, napr.: zatím neoslovat..."
                      clearable
                    ></v-textarea>
                  </v-col>
                  <v-col :cols="12">
                    <!-- odosielat/neodosielat -->
                    <v-checkbox
                      v-model="dataForm.is_allowed"
                      :label="label_is_allowed"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col :cols="12">
                    <!-- dovod neodosielania -->
                    <v-select
                      v-model="dataForm.reason_reject"
                      :items="items_reason_reject"
                      :rules="reason_rejectRules"
                      label="Zvolte důvod, proč se kontaktu nebude odesílat newsletter"
                      v-show="show_reason_reject"
                      clearable
                    ></v-select>
                    <div v-if="item_id && item_id.length > 0 && dataForm.reason_reject === 20" class="v-messages__message"><b>Důvod je nutno zvolit!</b></div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <!-- TLACITKA -->
          <v-card-actions style="position:absolute;bottom:10px;right:10px">
            <v-row>
              <v-col :cols="12" style="text-align:right">
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDialog()"
                >
                  Zrušit
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :disabled="btn_save_disabled"
                  @click="saveDialog()"
                >
                  Uložit
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
/* eslint prefer-object-spread: "off" */
import axios from 'axios'
import { mdiEmailEdit } from '@mdi/js'
import response from '@/mixins/response'
import validate from '@/mixins/validate'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response, validate],
  props: {
    tabs_h: {
      type: Number,
      default: 250,
    },
    show: {
      type: Boolean,
      default: false,
    },
    item_id: {
      type: String,
      default: undefined,
    },
  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
    'dataForm.email_address': function () {
      this.formValid()
    },
    'dataForm.reason_reject': function (new_reason) {
      console.log('watch dataForm.reason_reject', this.dataForm.reason_reject)

      // if (!new_reason) {
      //   this.dataForm.reason_reject = -1
      // }
      this.formValid()
    },
    'dataForm.is_allowed': function (new_val) {
      if (new_val !== true) {
        this.label_is_allowed = 'neaktívny(newsletter sa kontaktu neodošle)'
        this.dataForm.reason_reject = this.item_id.length === 0 ? 20 : (this.dataForm.reason_reject || 20)
      } else {
        this.label_is_allowed = 'aktívny(newsletter sa kontaktu odošle)'
        this.dataForm.reason_reject = 5
      }
      this.show_reason_reject = !new_val // zobraz alebo schovaj dovod zneaktivnenia(is_allowed)
      this.formValid()
    },
    item_id: function (new_id) {
      if (new_id && new_id.length > 0) {
        this.detailItem()
      } else {
        this.resetForm()
        this.dataForm.is_allowed = true
      }
    },
  },
  beforeDestroy() {
  },
  data() {

    return {
      emailRules: [
        v => !!v || 'Emailová adresa je požadovaný údaj!',
        v => (v && this.isValidateEmail(v)) || 'Nesprávny formát emailovej adresy!',
      ],
      reason_rejectRules: [
        v => !!v || 'Důvod je nutno zvolit!',
      ],

      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      form_valid: true,
      dataForm: {},
      default_dataForm: {
        email_address: '',
        email_contact_name: '',
        note: '',
        is_allowed: true, // true - povoleny | false - zakazany(nebude sa posielat)
        reason_reject: 5, // 5 - is_allowed rovna sa true, cize nie je odmietnuty | 20 - uzivatel INDEXu vypol alebo este neaktivoval posielanie na tuto adresu ... dalsie su v o funkcii reasonRejectToText()
      },

      // icony
      icons: {
        mdiEmailEdit,
      },

      label_is_allowed: 'aktívny(newsletter sa kontaktu odošle)',
      btn_save_disabled: true,

      show_reason_reject: false,
      items_reason_reject: [{
        value: 20,
        text: this.reasonRejectToText(20),
        disabled: true,
      }, {
        value: 40,
        text: this.reasonRejectToText(40),
      }, {
        value: 90,
        text: this.reasonRejectToText(90),
      }],
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    closeDialog(p_refresh) {
      this.$emit('close', false, p_refresh)
      this.resetForm()
    },
    saveDialog() {
      console.log('save dialog')
      if (this.$refs.form.validate()) {
        let config
        if (this.item_id) { // UPDATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/${this.item_id}`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'put',
          }
        } else { // CREATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
          }
        }
        axios(config)
          .then(resp => {
            console.log('newsletter | saveDialog => ', resp)
            this.closeDialog(true)
          })
          .catch(err => {
            console.error(err)
            this.snack_text = `To nebude fungovať! ${this.responseError(err)}`
            this.snack_color = 'red'
            this.snackbar = true
          })
      }
    },
    detailItem() {
      if (this.item_id) {
        let config = {
          url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/${this.item_id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'get',
        }

        // ---
        axios(config)
          .then(resp => {
            // console.log('newsletter | detailItem => ', resp.data)
            let detail = resp.data.db_data
            if (detail) {
              this.dataForm.email_address = detail.email_address
              this.dataForm.email_contact_name = detail.email_contact_name
              this.dataForm.note = detail.note
              this.dataForm.is_allowed = detail.is_allowed
              this.dataForm.reason_reject = detail.reason_reject
            } else {
              this.snack_text = 'Ajaj! Detail nebol nájdený!'
              this.snack_color = 'red'
              this.snackbar = true
              this.closeDialog()
            }
          })
          .catch(err => {
            console.error(err)
            this.snack_text = `Ajaj! ${this.responseError(err)}`
            this.snack_color = 'red'
            this.snackbar = true
          })
      }
    },
    resetForm() {
      // this.$refs.form.reset()

      this.dataForm = JSON.parse(JSON.stringify(this.default_dataForm))
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    reasonRejectToText(p_number) {
      let result = ''
      switch (p_number) {
        case 20:
          result = 'uživatel při vytváření kontaktu, neaktivoval posílání na tuto emailovú adresu'
          break
        case 30:
          result = 'emailový kontakt se sám odhlasil z odběru newsletteru (odkazem)'
          break
        case 40:
          result = 'emailový kontakt nás kontaktoval, že chce být vyřazen z odběru'
          break
        case 90:
          result = 'jiný důvod'
          break
        default:
      }

      return result
    },
    formValid() {
      if (this.isValidateEmail(this.dataForm.email_address) === false || (this.dataForm.is_allowed === false && this.dataForm.reason_reject <= 20 && this.item_id.length > 0)) { // pri vytvarani noveho kontaktu sa moze nastavit neodosielat a dovod nemusi uviest automaticky sa nastavi 20
        this.btn_save_disabled = true
      } else {
        this.btn_save_disabled = false
      }
    },
  },
}

</script>

<style>

</style>
