<template>
  <div>

    <v-card
      :style="`height:${tabs_h}px;margin-bottom:25px;`">
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- taby -->
      <v-tabs-items v-model="tab">
        <!-- tab: EMAIL MANAGEMENT -->
        <v-tab-item>
          <email-management
            :tabs_h="tabs_h"
            @snackbar="snackBarMsg">
          </email-management>
        </v-tab-item>
        <!-- tab: EMAIL TEMPLATE -->
        <v-tab-item>
          <email-template
            :tabs_h="tabs_h"
            @snackbar="snackBarMsg">
          </email-template>
        </v-tab-item>
        <!-- tab: EMAIL TIMER -->
        <v-tab-item>
          <email-timer
            :tabs_h="tabs_h"
            @snackbar="snackBarMsg">
          </email-timer>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */

import { mdiAccountGroup, mdiFileDocumentEditOutline, mdiSortVariantRemove, mdiExportVariant, mdiTimerOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'
import EmailManagement from './components/EmailManagement.vue'
import EmailTemplate from './components/EmailTemplate.vue'
import EmailTimer from './components/EmailTimer.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    'email-management': EmailManagement,
    'email-template': EmailTemplate,
    'email-timer': EmailTimer,
  },
  mixins: [response],
  data() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Správa kontaktů', icon: mdiAccountGroup },
      { title: 'Šablóna emailu', icon: mdiFileDocumentEditOutline },
      { title: 'Časovač', icon: mdiTimerOutline },
    ]

    return {
      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      // vcard tabs
      tab,
      tabs,
      tabs_h: 400,

      // Icons
      icons: {
        mdiAccountGroup,
        mdiFileDocumentEditOutline,
        mdiSortVariantRemove,
        mdiExportVariant,
        mdiTimerOutline,
      },
    }
  },
  computed: {

  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
  },
  methods: {
    snackBarMsg(p_data) {
      this.snackbar = true
      this.snack_color = p_data.snack_color
      this.snack_text = p_data.snack_text
    },
    windowResize() {
      // window.innerWidth
      this.tabs_h = window.innerHeight - 180
      console.log('tabs_h =>', this.tabs_h)
    },
  },
}
</script>

<style>

</style>
