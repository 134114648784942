<template>
  <div>
    <v-progress-linear indeterminate style="margin-top:1px;" v-show="detail_loading"></v-progress-linear>
    <div
      class="karta">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <!-- SUBJECT -->
        <v-text-field
          v-model="dataForm.email_subject"
          :counter="50"
          :rules="emailSubjectRules"
          label="Předmet"
          required
          style="margin-bottom: 15px"
          clearable
          validate-on-blur
        ></v-text-field>

        <!-- BODY -->
        Telo emailu
        <tiptap-vuetify
          v-model="dataForm.email_body"
          :toolbar-attributes="{ color: '#ffffff' }"
          :card-props="{ flat: true, color: '#fbfcfc' }"
          :extensions="extensions"
          :style="`margin-bottom:15px;height:${tabs_h - 360}px;overflow-y: auto;`"
          clearable
        >
        </tiptap-vuetify>

        <!-- PRILOHY EMAILU -->
        <v-list
          v-if="dataForm.file_name && dataForm.file_name.length > 0 && template_id"
          subheader
          two-line
          style="border-bottom: 1px dotted #BDBDBD;border-top: 1px dotted #BDBDBD;margin-bottom: 15px;"
        >
          <v-row>
            <v-col :cols="9">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon
                    color="primary"
                    dark
                    v-text="icons.mdiFilePdfBox"
                  ></v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="dataForm.file_name"></v-list-item-title>

                  <v-list-item-subtitle>
                    velikost: {{Math.round(dataForm.file_size / 1024)}} kB
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    naposledy vloženo: {{ fullDate(dataForm.attachment_date_changed) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-col>
            <v-col :cols="3">
              <div style="line-height:82px;text-align:center;">
                <v-btn
                  color="#F44336"
                  text
                  @click="dialog_remove=true">
                  Odstránit přílohu
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-list>
        <v-file-input
          v-else
          v-model="file_attachment"
          accept="application/pdf"
          multiple
          label="Prílohy emailu"
          @change="handleFileChange"
        ></v-file-input>

        <!-- TLACITKA -->
        <v-row>
          <v-col col="4">
            <!-- otestuj emailovu sablonu -->
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              rounded
              @click="sendTest"
              title="Odeslat zkušební email na vaši emailovú adresu pro kontrolu."
              style="margin-top:10px"
              small
              :loading="btn_test_loading"
            >
            <span v-if="isMobile">TEST</span>
            <span v-else>ZKUŠEBNÍ EMAIL</span>
            </v-btn>
          </v-col>
          <!-- uloz emailovu sablonu -->
          <v-col col="8" style="text-align:right">
            <v-btn
              :disabled="btn_save_disabled"
              color="primary"
              class="mx-2"
              fab
              dark
              title="Uložiť šablónu"
              :loading="btn_save_loading"
              @click="saveTemplate"
            >
              <v-icon style="color:white">
                {{ icons.mdiContentSave }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <!-- DIALOG: ODSTRANIT PRILOHU -->
    <v-dialog
      v-model="dialog_remove"
      max-width="499"
    >
      <v-card>
        <v-card-title class="text-h5">
          Odebrat přílohu z emailové šablony?
        </v-card-title>

        <v-card-text>
          Pozn.: příloha bude odebrána přímo z DB
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog_remove = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="removeAttach"
          >
            Odebrat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
/* eslint no-unneeded-ternary: "off" */
import axios from 'axios'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, Image } from 'tiptap-vuetify'
import { mdiContentSave, mdiExportVariant, mdiFilePdfBox } from '@mdi/js'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    TiptapVuetify,
    'snack-bar': SnackBar,
  },
  mixins: [response],
  props: {
    tabs_h: {
      type: Number,
      default: 250,
    },
    extensions: {
      type: Array,
      default: () => [
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading, {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak,
        Image,
      ],
    },
  },
  data() {

    return {
      valid: true,
      emailSubjectRules: [
        v => !!v || 'Předmet emailu musí byť vyplnený',
        v => (v && v.length >= 8) || 'Předmet emailu musí obsahovať aspoň 8 znakov',
      ],
      email_body: '',
      file_attachment: null,

      isMobile: false,

      template_id: undefined,

      dataForm: {
        email_subject: '',
        email_body: '',
        file_name: undefined,
        file_size: undefined,
      },

      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      // icons
      icons: {
        mdiContentSave,
        mdiExportVariant,
        mdiFilePdfBox,
      },

      btn_save_disabled: true,
      btn_save_loading: false,
      btn_test_loading: false,

      detail_loading: true,
      dialog_remove: false,
    }
  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
    'dataForm.email_subject': function (new_val, old_val) {
      this.formValid()
    },
    'dataForm.email_body': function (new_val, old_val) {
      if (this.detail_loading) return
      this.btn_save_disabled = old_val !== new_val ? false : true
    },
  },
  mounted() {
    // Skontroluj, či ide o mobilné zariadenie alebo tablet
    this.isMobile = this.$vuetify.breakpoint.mobile || this.$vuetify.breakpoint.tablet
  },
  created() {
    this.detailTemplate()
  },
  methods: {
    validate() {
      this.$refs.form.validate()
    },
    formValid() {
      if (this.dataForm.email_subject && this.dataForm.email_subject.length >= 8 && this.detail_loading === false) {
        this.btn_save_disabled = false
      } else {
        this.btn_save_disabled = true
      }
    },
    saveTemplate() {
      if (this.$refs.form.validate()) {
        this.btn_save_loading = true
        let config
        if (this.template_id) { // UPDATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/email_template/${this.template_id}`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'put',
          }
        } else { // CREATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/email_template`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
          }
        }

        // ---
        axios(config)
          .then(resp => {
            this.snack_text = 'Emailová šablóna byla uložená!'
            this.snack_color = 'success'
            this.snackbar = true
            this.btn_save_loading = false
            this.btn_save_disabled = true

            // console.log('newsletter | saveTemplate => ', resp)
          })
          .catch(err => {
            this.btn_save_loading = false
            console.error(err)
            this.snack_text = `Ejha! Zrada! ${this.responseError(err)}`
            this.snack_color = 'red'
            this.snackbar = true
          })
      }
    },
    handleFileChange() {
      this.dataForm.file_content_base64 = ''
      const reader = new FileReader()

      reader.onload = () => {
        this.dataForm.file_content_base64 = reader.result.split(',')[1]
        this.dataForm.file_name = this.file_attachment[0].name
        this.dataForm.file_size = this.file_attachment[0].size
        this.btn_save_disabled = false

        // console.log(this.dataForm.file_content_base64)
      }

      if (this.file_attachment && this.file_attachment.length > 0) {
        const file = this.file_attachment[0]
        console.log(file)
        reader.readAsDataURL(file)
      }
    },
    detailTemplate() {
      this.detail_loading = true
      let config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/email_template/default`, // ak default, nacita prvu v poradi(plan je ze bude vzdy len jedna)
        data: JSON.stringify(this.dataForm),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }

      // ---
      axios(config)
        .then(resp => {
          this.file_attachment = null
          this.dataForm.file_content_base64 = ''
          this.dataForm.file_name = ''
          this.dataForm.file_size = ''
          let data = (resp && resp.data && resp.data.db_data) || undefined
          if (data) {
            this.dataForm.email_subject = data.email_subject
            this.dataForm.email_body = data.email_body
            this.dataForm.file_name = data.attachment_name
            this.dataForm.file_size = data.attachment_size
            this.template_id = data._id
          }
          setTimeout(() => {
            this.detail_loading = false
          }, 500)
        })
        .catch(err => {
          this.detail_loading = false
          console.error(err)
          this.snack_text = `Zrada! Emailovú šablónu sa nepodarilo načítať. ${this.responseError(err)}`
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    removeAttach() {
      // this.dataForm.file_name = undefined
      // this.dataForm.file_size = undefined
      let config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/email_template/${this.template_id}/remove_attachment`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'delete',
      }
      axios(config)
        .then(resp => {
          this.snack_text = 'Příloha byla odstráněna z emailové šablóny!'
          this.snack_color = 'success'
          this.snackbar = true
          this.btn_save_loading = false
          this.dialog_remove = false

          this.detailTemplate()

          // console.log('newsletter | saveTemplate => ', resp)
        })
        .catch(err => {
          this.btn_save_loading = false
          console.error(err)
          this.snack_text = `Ejha! Zrada! ${this.responseError(err)}`
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    fullDate(p_date) {
      return this.$moment(p_date).format('DD.MM.YYYY HH:mm:ss')
    },
    sendTest() {
      this.btn_test_loading = true
      let config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/email_template/send_test/email`,
        data: JSON.stringify(this.dataForm),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }

      // ---
      axios(config)
        .then(resp => {
          this.snack_text = 'Testovací email byl odeslán na vaši emailovú adresu.'
          this.snack_color = 'success'
          this.snackbar = true
          this.btn_test_loading = false
        })
        .catch(err => {
          this.detail_loading = false
          console.error(err)
          this.snack_text = `Ajaj! Testovací email se nezdařilo odeslať. ${this.responseError(err)}`
          this.snack_color = 'red'
          this.snackbar = true
          this.btn_test_loading = false
        })
    },
  },
}

</script>

<style>
  .karta {
    padding: 30px
  }
</style>
