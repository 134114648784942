<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon>
            {{ icons.mdiEmailEdit }}
          </v-icon>
          <span class="text-h5" style="margin-left:10px">Import emailových kontaktů</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <v-file-input
                v-model="file_to_import"
                accept="text/csv"
                label="Soubor s kontaktmi"
              ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <small><b>Info:</b> podporovaný formát souboru je <u>csv</u></small>
        </v-card-text>

        <!-- TLACITKA -->
        <v-card-actions>
          <v-row>
            <v-col :cols="2">
              <v-icon
                color="primary darken-1"
                @click="show_help_dialog=true"
                style="margin-top:7px;cursor:help;"
                >
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-col>
            <!-- btn: zrusit -->
            <v-col :cols="10" style="text-align: right;">
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDialog"
              >
                Zrušit
              </v-btn>
              <!-- btn: import -->
              <v-btn
                color="primary darken-1"
                text
                :loading="btn_import_loading"
                :disabled="btn_import_disabled"
                @click="importFile()"
              >
                Import
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG: help -->
    <v-dialog
      width="480px"
      v-model="show_help_dialog">
      <v-card>
        <v-card-title class="text-h5">
          Co je csv formát?
        </v-card-title>

        <v-card-text>
          <p>
          je to jednoduchý souborový formát určený pro výměnu tabulkových dat.
          Soubor ve formátu CSV obsahuje řádky, ve kterých jsou jednotlivé položky odděleny znakem <span style="color:#9C27B0">středník</span> <b style="color:#9C27B0">;</b> nebo jiným znakem.
          Náš import očekáva znak <b style="color:#9C27B0">;</b>
          </p>
          <div>Formát csv je možné vyexportovať nebo editovat i pomocí aplikace <b>Microsoft Excel</b>. Klidně si však vystačíte i s <b>Poznámkovým blokem</b> ve Windows.</div>
          <div><b>Kontakt, který se už v DB nachází nebude přepsaný.</b></div>
          <div style="margin-top:10px">
            <b>Příklad:</b>
            <div>rool@irool.cz<b>;</b>RooL<b>;</b></div>
            <div>jozko@mrkvicka.sk<b>;</b>Jozef Mrkvička (Mrkváren)<b>;</b></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="show_help_dialog = false"
          >
            Rozumím
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </v-row>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
import axios from 'axios'
import { mdiEmailEdit, mdiHelpCircleOutline } from '@mdi/js'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [],
  props: {
    tabs_h: {
      type: Number,
      default: 250,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
    show: function (is_show) {
      console.log('show_dialog_email_crud =>', is_show)
    },
    file_to_import: function (new_file) {
      console.log(new_file)
      if (new_file) {
        this.btn_import_disabled = false
      } else {
        this.btn_import_disabled = true
      }
    },
  },
  data() {

    return {
      file_to_import: null,
      btn_import_disabled: true,
      btn_import_loading: false,
      show_help_dialog: false,

      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      // icony
      icons: {
        mdiEmailEdit,
        mdiHelpCircleOutline,
      },
    }
  },
  created() {
  },
  methods: {
    closeDialog(p_refresh) {
      this.file_to_import = null
      this.$emit('close', false, p_refresh)
    },
    importFile() {
      this.btn_import_loading = true
      const formData = new FormData()
      formData.append('file', this.file_to_import)
      axios.post(`${process.env.VUE_APP_BASE_API}/api/v2/newsletter/import`, formData)
        .then(resp => {
          this.snack_text = 'Import byl dokončen!'
          this.snack_color = 'success'
          this.snackbar = true
          this.btn_import_loading = false
          this.closeDialog(true)
        })
        .catch(err => {
          this.btn_import_loading = false
          console.error(err)
          this.snack_text = `Něco se pokazilo! ${this.responseError(err)}`
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
  },
}

</script>

<style>

</style>
