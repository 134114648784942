<template>
  <div style="margin-top:10px;margin-botto:15px;word-break:break-word;padding:10px;border:1px solid #e9e9e9;border-radius:3px">
    <pre v-html="syntaxHighlight(json_data)"></pre>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
/* eslint no-useless-escape: "off" */
export default {
  props: {
    json_data: {
      default: {},
    },
  },
  created() {
  },
  methods: {
    syntaxHighlight(p_json) {
      if (!p_json) {
        return ''
      }
      p_json = JSON.stringify(p_json, undefined, 2)
      p_json = p_json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

      return p_json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number'
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key'
          } else {
            cls = 'string'
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean'
        } else if (/null/.test(match)) {
          cls = 'null'
        }

        return `<span class="${cls}">${match}</span>`
      })
    },
  },
}
</script>

<style>
  pre {
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
    /* outline: 1px solid #ccc; padding: 5px; margin: 5px;  */
  }
  .string { color: rgb(231, 127, 58); }
  .number { color: rgb(17, 0, 255); }
  .boolean { color: blue; }
  .null { color: magenta; }
  .key { color: rgb(8, 139, 59); }
</style>
