<template>
  <div>
    <v-progress-linear indeterminate style="margin-top:1px;" v-show="timer_detail_loading"></v-progress-linear>
    <div
      class="karta">
      <v-card
        class="mx-auto my-12"
        max-width="600"
      >
        <v-card-title>Nastavení harmongramu</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <!-- typ harmonogramu -->
            <v-row class="mx-0">
              <v-col :cols="12">
                <v-select
                  v-model="dataForm.scheduler_type"
                  :items="items"
                  item-text="name"
                  item-value="val"
                  label="Plán odesílaní"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <!-- nastavenie kedy -->
            <v-row class="mx-0">
              <v-col :cols="12">
                <!-- DAY OF MONTH -->
                <v-select
                  v-if="dataForm.scheduler_type === 'month'"
                  v-model="dataForm.day_of_month"
                  label="Den v měsící"
                  :items="items_month"
                  item-text="name"
                  item-value="val"
                  hint="Zvolte, který den v měsíci se bude odesílat newsletter"
                  style="margin-bottom: 15px"
                ></v-select>
                <!-- DAY OF WEEK -->
                <v-select
                v-if="dataForm.scheduler_type === 'week'"
                  v-model="dataForm.day_of_week"
                  label="Den v týdnu"
                  :items="items_week"
                  item-text="name"
                  item-value="val"
                  hint="Zvolte, který den v týdnu se bude odesílat newsletter"
                  style="margin-bottom: 15px"
                ></v-select>
                <!-- HOUR OF DAY -->
                <v-select
                  v-if="dataForm.scheduler_type === 'day'"
                  v-model="dataForm.hour_of_day"
                  label="Hodina"
                  :items="items_day"
                  item-text="name"
                  item-value="val"
                  hint="Zvolte, ve kterou hodinu se bude odesílat newsletter"
                  style="margin-bottom: 15px"
                ></v-select>
              </v-col>
            </v-row>
            <!-- aktivacia -->
            <v-row class="mx-0">
              <v-col :cols="12">
                <v-switch
                  v-model="dataForm.scheduler_active"
                  inset
                  :label="`autoodesílaní ${dataForm.scheduler_active ? 'aktivováno' : 'neaktivováno'}`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!-- TLACITKA -->
        <v-card-actions>
          <v-row>
            <v-col :cols="12" style="text-align:right">
              <v-btn
                color="primary lighten-2"
                text
                :loading="btn_save_loading"
                @click="saveScheduler"
              >
                Nastavit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-divider class="mx-4"></v-divider>

        <v-card-text style="margin-top:30px">
          <div><b>Datum plánovaného odeslání</b></div>
          <div style="text-align:center;">
            <v-chip
              v-if="date_plan === null"
              class="ma-2"
              label>
              Ještě nebylo naplánováno
            </v-chip>
            <v-chip
              v-else-if="scheduler_active_info === false"
              color="#F44336"
              class="ma-2"
              label>
              <span style="padding-left:15px;padding-right:15px;">zastaveno</span>
            </v-chip>
            <v-chip
              v-else
              color="primary"
              class="ma-2"
              label>
              <span style="padding-left:15px;padding-right:15px;">{{ date_plan }}</span>
            </v-chip>
          </div>
        </v-card-text>

      </v-card>

      <!-- SNACKBAR -->
      <snack-bar
        :snackbar="snackbar"
        :color="snack_color"
        :text="snack_text"
        @close="snackbar=false"
      >
      </snack-bar>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
/* eslint no-case-declarations: "off" */
import axios from 'axios'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  props: {
    tabs_h: {
      type: Number,
      default: 250,
    },
  },
  data() {

    return {
      valid: false,

      dataForm: {
        scheduler_active: false,
        scheduler_type: 'month',
        day_of_month: 1,
        day_of_week: 1,
        hour_of_day: 1,
      },
      items: [
        { name: 'měsíčne', val: 'month' },
        { name: 'týdne', val: 'week' },
        { name: 'denne', val: 'day' },
      ],
      items_month: [
        { name: '1', val: 1 },
        { name: '2', val: 2 },
        { name: '3', val: 3 },
        { name: '4', val: 4 },
        { name: '5', val: 5 },
        { name: '6', val: 6 },
        { name: '7', val: 7 },
        { name: '8', val: 8 },
        { name: '9', val: 9 },
        { name: '10', val: 10 },
        { name: '11', val: 11 },
        { name: '12', val: 12 },
        { name: '13', val: 13 },
        { name: '14', val: 14 },
        { name: '15', val: 15 },
        { name: '16', val: 16 },
        { name: '17', val: 17 },
        { name: '18', val: 18 },
        { name: '19', val: 19 },
        { name: '20', val: 20 },
        { name: '21', val: 21 },
        { name: '22', val: 22 },
        { name: '23', val: 23 },
        { name: '24', val: 24 },
        { name: '25', val: 25 },
        { name: '26', val: 26 },
        { name: '27', val: 27 },
        { name: '28', val: 28 },
      ],
      items_week: [
        { name: 'Pondělí', val: 1 },
        { name: 'Úterý', val: 2 },
        { name: 'Středa', val: 3 },
        { name: 'Čtvrtek', val: 4 },
        { name: 'Pátek', val: 5 },
        { name: 'Sobota', val: 6 },
        { name: 'Neděle', val: 7 },
      ],
      items_day: [
        { name: '00', val: 0 },
        { name: '01', val: 1 },
        { name: '02', val: 2 },
        { name: '03', val: 3 },
        { name: '04', val: 4 },
        { name: '05', val: 5 },
        { name: '06', val: 6 },
        { name: '07', val: 7 },
        { name: '08', val: 8 },
        { name: '09', val: 9 },
        { name: '10', val: 10 },
        { name: '11', val: 11 },
        { name: '12', val: 12 },
        { name: '13', val: 13 },
        { name: '14', val: 14 },
        { name: '15', val: 15 },
        { name: '16', val: 16 },
        { name: '17', val: 17 },
        { name: '18', val: 18 },
        { name: '19', val: 19 },
        { name: '20', val: 20 },
        { name: '21', val: 21 },
        { name: '22', val: 22 },
        { name: '23', val: 23 },
      ],

      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      date_plan: null, // najblizsie spustenie
      btn_save_loading: false,
      scheduler_id: undefined,
      timer_detail_loading: false,
      scheduler_active_info: false,
    }
  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
  },
  created() {
    this.detailScheduler()
  },
  methods: {
    saveScheduler() {
      if (this.$refs.form.validate()) {
        this.btn_save_loading = true
        let config
        if (this.scheduler_id) { // UPDATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/timer/${this.scheduler_id}`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'put',
          }
        } else { // CREATE
          config = {
            url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/timer`,
            data: JSON.stringify(this.dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
          }
        }

        // ---
        axios(config)
          .then(resp => {
            this.snack_text = 'Data harmonogramu byla uložena!'
            this.snack_color = 'success'
            this.snackbar = true
            this.btn_save_loading = false
            this.detailScheduler()
          })
          .catch(err => {
            this.btn_save_loading = false
            console.error(err)
            this.snack_text = `Ejha! Zrada! ${this.responseError(err)}`
            this.snack_color = 'red'
            this.snackbar = true
          })
      }
    },
    detailScheduler() {
      this.timer_detail_loading = true
      let config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/newsletter/timer/default`, // ak default, nacita prvu v poradi(plan je ze bude vzdy len jedna)
        data: JSON.stringify(this.dataForm),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }

      // ---
      axios(config)
        .then(resp => {
          let data = (resp && resp.data && resp.data.db_data) || undefined
          if (data) {
            this.dataForm.scheduler_active = data.scheduler_active
            this.scheduler_active_info = data.scheduler_active
            this.dataForm.scheduler_type = data.scheduler_type
            this.dataForm.day_of_month = data.day_of_month
            this.dataForm.day_of_week = data.day_of_week
            this.dataForm.hour_of_day = data.hour_of_day
            this.scheduler_id = data._id
            this.calculateDatePlan()
          }
          setTimeout(() => {
            this.timer_detail_loading = false
          }, 500)
        })
        .catch(err => {
          this.timer_detail_loading = false
          console.error(err)
          this.snack_text = `Zrada! Emailovú šablónu sa nepodarilo načítať. ${this.responseError(err)}`
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    calculateDatePlan() {
      const data = this.dataForm
      let str_date = ''
      let cal_date = null

      const d = new Date()
      const den = d.getDate()
      const mesiac = d.getMonth() + 1 // Mesiace sa počítajú od 0, preto sa pripočíta 1
      const rok = d.getFullYear()

      switch (data.scheduler_type) {
        case 'week':
          let dnes = this.$moment()
          dnes = dnes.startOf('day').add(1, 'minute')

          cal_date = dnes.clone().day(this.dataForm.day_of_week)
          if (dnes.day() >= this.dataForm.day_of_week) {
            cal_date.add(7, 'days')
          }
          break
        case 'day':
          str_date = `${den}.${mesiac}.${rok} ${data.hour_of_day}:00:00.000`
          cal_date = this.$moment(str_date, 'D.M.YYYY HH:mm:ss.sss')
          if (this.$moment(new Date()).isAfter(cal_date)) {
            cal_date = cal_date.add(1, 'day')
          }
          break
        default: // month
          str_date = `${data.day_of_month}.${mesiac}.${rok}`
          cal_date = this.$moment(str_date, 'D.M.YYYY')
          cal_date = cal_date.startOf('day').add(1, 'minute')
          if (this.$moment(new Date()).isAfter(cal_date)) {
            cal_date = cal_date.add(1, 'month')
          }
      }

      // console.log(data.scheduler_type, '==>', this.$moment(cal_date).format('DD.MM.YYYY'))
      this.date_plan = this.$moment(cal_date).format('DD.MM.YYYY HH:mm')
    },
  },
}

</script>

<style>

</style>
