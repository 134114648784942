import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"karta"},[_c(VBtn,{staticClass:"mx-2",staticStyle:{"top":"5px","right":"30px"},attrs:{"color":"primary","fab":"","top":"","right":"","absolute":"","title":"Přidat emailovú adresu"},on:{"click":function($event){_vm.show_dialog_email_crud=true}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1),_c(VBtn,{staticClass:"mx-2",staticStyle:{"top":"15px","right":"90px"},attrs:{"color":"primary","fab":"","top":"","right":"","absolute":"","small":"","title":"Import z csv"},on:{"click":function($event){_vm.show_dialog_email_import=true}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiFileImportOutline)+" ")])],1),_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Hledej","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"full-width":"","dense":"","headers":_vm.headers,"items":_vm.table_data,"height":_vm.tabs_h - 220,"no-data-text":"Data nenalezena...","items-per-page":_vm.fetch_settings.paging.limit,"server-items-length":_vm.fetch_settings.paging.total_records,"page":_vm.fetch_settings.paging.current_page,"footer-props":{ itemsPerPageOptions: [20, 50, 200, 600], itemsPerPageText: 'Počet položek na stránku' },"sort-by":"date_last_send","sort-desc":true},on:{"update:options":_vm.tableOptChanged},scopedSlots:_vm._u([{key:"item.email_address",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link-cell",on:{"click":function($event){return _vm.setModifyItem(item)}}},[_vm._v(_vm._s(item.email_address))]),(item.reason_reject===30)?_c('span',[_c(VIcon,{staticStyle:{"margin-left":"5px","color":"#9C27B0","cursor":"help"},attrs:{"title":"emailový kontakt se sám odhlasil z odběru newsletteru (odkazem)","x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmoticonSadOutline)+" ")])],1):_vm._e(),(item.reason_reject===40)?_c('span',[_c(VIcon,{staticStyle:{"margin-left":"5px","color":"#FF80AB","cursor":"help"},attrs:{"title":"emailový kontakt nás kontaktoval, že chce být vyřazen z odběru","x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmoticonSadOutline)+" ")])],1):_vm._e(),(item.reason_reject===90)?_c('span',[_c(VIcon,{staticStyle:{"margin-left":"5px","color":"#E91E63","cursor":"help"},attrs:{"title":"jiný důvod","x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmoticonSadOutline)+" ")])],1):_vm._e()]}},{key:"item.date_last_send",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (item.date_last_send ? 'link-cell' : '')),on:{"click":function($event){return _vm.logHistory(item)}}},[_vm._v(_vm._s(_vm.lastSend(item.date_last_send)))]),(item.last_history_id && item.last_history_id.result && item.last_history_id.result.err)?_c(VIcon,{staticStyle:{"margin-left":"5px","color":"#FF6F00","cursor":"help"},attrs:{"title":"Při posledním odeslání došlo k chybě.","x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertRhombusOutline)+" ")]):_vm._e()]}},{key:"item.is_allowed",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{staticStyle:{"margin-top":"0px"},attrs:{"readonly":"","hide-details":"","title":item.is_allowed ? 'Odesílaní newsletteru pro tento kontakt je povoleno.' : 'Odesílaní newsletteru pro tento kontakt je zakázáno.'},model:{value:(item.is_allowed),callback:function ($$v) {_vm.$set(item, "is_allowed", $$v)},expression:"item.is_allowed"}})]}}])})],1),_c('div',{staticClass:"stats"},[_vm._v("celkom kontaktov: "+_vm._s(_vm.contacts_total)+" | z toho odhlásených kontaktov: "+_vm._s(_vm.contacts_disabled)+" | zostáva aktívnych kontaktov: "+_vm._s(_vm.contacts_enabled))]),_c('div',{staticStyle:{"display":"block","height":"20px"}})],1),_c('dialog-email-crud',{attrs:{"show":_vm.show_dialog_email_crud,"item_id":_vm.item_id},on:{"close":_vm.closeDialogEmailCRUD}}),_c('dialog-email-import',{attrs:{"show":_vm.show_dialog_email_import},on:{"close":_vm.closeDialogEmailImport}}),_c('dialog-email-history',{attrs:{"show":_vm.show_dialog_email_history,"history_item_id":_vm.history_item_id},on:{"close":_vm.closeDialogEmailHistory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }