// eslint-disable-next-line object-curly-newline
/* eslint arrow-body-style: ["off", "always"] */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */

export default {
  data() {
    return {

    }
  },
  created() {
    console.log('mixins validate loaded...')
  },
  methods: {
    isValidateEmail(p_val) {
      // const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (p_val && p_val.match(validRegex)) {
        return true
      }

      return false
    },
  },
}
