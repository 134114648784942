import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDatabaseClockOutline)+" ")]),_c('span',{staticClass:"text-h5",staticStyle:{"margin-left":"10px"}},[_vm._v("Historie odesílaní")])],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VDataTable,{attrs:{"full-width":"","height":"600","dense":"","headers":_vm.headers,"items":_vm.table_data_history,"no-data-text":"Data nenalezena...","item-key":"_id","show-expand":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Získávam data ze serveru... Prosím čekejte","items-per-page":_vm.fetch_settings.paging.limit,"server-items-length":_vm.fetch_settings.paging.total_records,"page":_vm.fetch_settings.paging.current_page,"footer-props":{ itemsPerPageOptions: [15, 30, 100], itemsPerPageText: 'Počet položek na stránku' },"sort-by":"createdon","sort-desc":true},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":_vm.tableOptChanged},scopedSlots:_vm._u([{key:"item.createdon",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.fullDate(item.createdon)))])]}},{key:"item.result.status",fn:function(ref){
var item = ref.item;
return [(item.result.status === true)?_c('span',{staticStyle:{"color":"#9C27B0"}},[_vm._v("odesláno")]):_c('span',{staticStyle:{"color":"#F44336"}},[_vm._v("neodesláno")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"padding-top":"10px","padding-bottom":"15px"}},[_c('json',{attrs:{"json_data":item.result.mailserver_info || item.result.err}})],1)])]}}])})],1)],1)],1)],1),_c(VCardActions,[_c(VRow,[_c(VCol,{attrs:{"cols":2}}),_c(VCol,{staticStyle:{"text-align":"right"},attrs:{"cols":10}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Zavřít ")])],1)],1)],1)],1)],1),_c('snack-bar',{attrs:{"snackbar":_vm.snackbar,"color":_vm.snack_color,"text":_vm.snack_text},on:{"close":function($event){_vm.snackbar=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }